// Prikazi obavijesti ajax formi
window.showNotification = function(data)  {

    $('#messageModal .modal-body .title').html('');
    $('#messageModal .modal-body .body').html('');

    if (data.success) {

        if ('msg' in data) {
            $('#messageModal .modal-body .title').html(data.msg);
            $('#messageModal .modal-body .body').html('');
            $('#messageModal .modal-content').attr('class', 'modal-content alert-success');
            $('#messageModal').modal('show');
            setTimeout("$('#messageModal').modal('hide')", 5000);
        }
    }
    else {
        if ('error' in data) {
            $('#messageModal .modal-body .title').html(data.error);

            $('#messageModal .modal-body .body').html('<ul></ul>');
            if ('errormsg' in data) {
                $('#messageModal .modal-body .body').find('ul').append('<li>'+data.errormsg+'</li>');
            }

            $('#messageModal .modal-content').attr('class', 'modal-content alert-danger');
            $('#messageModal').modal('show');
            setTimeout("$('#messageModal').modal('hide')", 5000);
        }
        else if ('errors' in data) {
            $('#messageModal .modal-body .title').html(data.errormsg);


            $('#messageModal .modal-body .body').html('<ul></ul>');

            $.each(data.errors, function(index, error) {
                $('#messageModal .modal-body .body').find('ul').append('<li>'+error+'</li>');
            });

            $('#messageModal .modal-content').attr('class', 'modal-content alert-danger');
            $('#messageModal').modal('show');
            setTimeout("$('#messageModal').modal('hide')", 5000);
        }

    }

}


// Prikazi obavijesti ajax formi
window.showSweetalert = function(data)  {

    if(data.autoclose) autoclose = data.autoclose;
    else autoclose = 3600;

    if (data.success) {

        if(data.type) type = data.type;
        else type = "success";

        sweetalert_class = 'sweetAlert-'+type;

        if ('msg' in data) {

            Swal.fire({
                "showConfirmButton":false,
                "timer":autoclose,
                "allowOutsideClick":true,
                "title":data.msg,
                "text":data.msg2,
                "type":type,
                "icon":type,
                "customClass": sweetalert_class,
            });

        }
    }
    else {

        if(data.type) type = data.type;
        else type = "error";

        sweetalert_class = 'sweetAlert'+type;

        if ('error' in data) {

            Swal.fire({
                "showConfirmButton":false,
                "timer":autoclose,
                "allowOutsideClick":true,
                "title":data.error,
                "text":data.errormsg,
                "type":type,
                "icon":type,
                "customClass": sweetalert_class,
            });

        }
        else if ('errors' in data) {

            var errors = "";

            $.each(data.errors, function(index, error) {
                errors += '<.modal-body .title class="text-msg-error">'+error+'</.modal-body .title>';
            });

            Swal.fire({
                "showConfirmButton":false,
                "timer":autoclose,
                "allowOutsideClick":true,
                "title":data.errormsg,
                "html":errors,
                "type":type,
                "icon":type,
                "customClass": sweetalert_class,
            });
        }

    }

}